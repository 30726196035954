<template>
  <div id="user-list">
    <!-- app drawer -->
    <category-aside
      v-if="formReady"
      v-model="isAddNewCategorySidebarActive"
      :resource="resource"
      @changed="loadCategories(); isAddNewCategorySidebarActive = false"
    ></category-aside>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="8"
          class="d-flex"
        >
          <search-filter
            v-model="filters"
            class="mr-2"
          />

          <v-select
            v-model="levelFilter"
            placeholder="Level"
            :items="levelOptions"
            class="mr-2"
            clearable
            item-text="title"
            item-value="value"
            outlined
            hide-details
          ></v-select>
          <v-select
            v-model="optOneFilter"
            placeholder="Single-level"
            :items="optOneOptions"
            class="mr-2"
            clearable
            item-text="title"
            item-value="value"
            outlined
            hide-details
          ></v-select>
          <distributor-id-filter v-model="filters">
          </distributor-id-filter>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <div class="d-flex justify-end flex-wrap">
            <template
              v-if="selectedRows.length"
            >
              <v-btn
                color="success"
                class="mb-4 me-3"
                :loading="loading"
                @click.stop="updateAllCategories({ active: 1 })"
              >
                <span>Activate</span>
              </v-btn>
              <v-btn
                color="warning"
                class="mb-4 me-3"
                :loading="loading"
                @click.stop="updateAllCategories({ active: 0 })"
              >
                <span>Deactive</span>
              </v-btn>
            </template>
            <v-btn
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="addNewResoure"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Category</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="categoryListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- ID -->
        <template #[`item.id`]="{item}">
          <span
            class="cursor-pointer"
            @click="setCategory(item)"
          >{{ item.id }}</span>
        </template>
        >
        <!-- ID -->
        <template #[`item.key`]="{item}">
          <span
            class="cursor-pointer"
            @click="setCategory(item)"
          >{{ item.key }}</span>
        </template>

        <!-- Category -->
        <template #[`item.name`]="{item}">
          <div
            class="cursor-pointer"
            @click="setCategory(item)"
          >
            {{ t(item.name) }}
          </div>
          <small v-if="item.distributor">
            DIST: {{ item.distributor.nicename || item.distributor.slug }}
          </small>
        </template>

        <!-- Code -->
        <template #[`item.code`]="{item}">
          <span
            class="cursor-pointer"
            @click="setCategory(item)"
          >
            {{ item.code }}
          </span>
        </template>

        <!-- Sub Category -->
        <template #[`item.subcategory`]="{item}">
          <v-chip
            v-for="subcat in item.sub_categories"
            :key="subcat.id"
            class="cursor-pointer"
            @click="setCategory(subcat)"
          >
            {{ subcat.key }}
            </v-chispan>
          </v-chip>
        </template>

        <!-- Type -->
        <template #[`item.type`]="{item}">
          {{ item.type }}
          </v-chip>
        </template>

        <!-- opt_one -->
        <template #[`item.opt_one`]="{item}">
          {{ item.opt_one ? 'Yes' : '' }}
          </v-chip>
        </template>

        <!-- Active -->
        <template #[`item.status`]="{item}">
          <v-chip :color="item.active ? 'success' : ''">
            {{ item.active ? 'Active' : 'Inactive' }}
            </v-chispan>
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setCategory(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="deleteCategory(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { destroyCategory } from '@api/common'
import {
mdiAccountOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiFileDocumentOutline,
mdiPencil,
mdiPlus,
mdiSquareEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { DistributorIdFilter, SearchFilter } from '@/components'
import { useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import { avatarText } from '@core/utils/filter'
import CategoryAside from '../category-resource/CategoryAside.vue'

import useCategoryList from './useCategoryList'

export default {
  components: {
    CategoryAside,
    DistributorIdFilter,
    SearchFilter,
  },
  setup() {
    const {
      categoryListTable,
      tableColumns,
      filters,
      totalListTable,
      loading,
      options,
      totalLocal,
      levelFilter,
      levelOptions,
      optOneFilter,
      optOneOptions,
      selectedRows,

      loadCategories,
      updateAllCategories,
    } = useCategoryList()

    const isAddNewCategorySidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const rootCategoryName = cats => {
      const cat = cats.find(c => !c.parent_id)

      return cat ? t(cat.name) : ''
    }

    // const categoriesOptions = commonStore.state.categories.map(cat => ({ title: t(cat.name), value: cat.id }))

    const deleteCategory = category => destroyCategory(category.id).then(loadCategories).catch(useNotifyErrors)

    const setCategory = category => {
      resource.value = category
      isAddNewCategorySidebarActive.value = true
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }
    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      isAddNewCategorySidebarActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      categoryListTable,
      tableColumns,
      filters,
      totalListTable,
      levelFilter,
      levelOptions,
      optOneFilter,
      optOneOptions,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      isAddNewCategorySidebarActive,
      formReady,
      resource,
      selectedRows,
      addNewResoure,

      avatarText,
      loadCategories,
      deleteCategory,
      setCategory,
      updateAllCategories,
      rootCategoryName,

      t,

      // icons
      icons: {
        mdiPencil,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
